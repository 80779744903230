import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

// 路由
export const routes = [{
    name: "login",
    path: "/login",
    component: () => import("@/views/login/index"),
    meta: {
      title: "智慧治水"
    }
  },
  // {
  //   name: "home",
  //   path: "/",
  //   component: () => import("@/views/index"),
  //   meta: {
  //     title: "智慧治水"
  //   }
  // },
  {
    name: "newhome",
    path: "/",
    component: () => import("@/views/index/newindex"),
    meta: {
      title: "智慧治水"
    }
  },
  {
    name: "newhome",
    path: "/cs",
    component: () => import("@/views/index/ceshi"),
    meta: {
      title: "ceshi"
    }
  },
  {
    name: "myself",
    path: "/my",
    component: () => import("@/views/index/my"),
    meta: {
      title: "个人中心"
    }
  },
  {
    name: "zhipai",
    path: "/zhipai",
    component: () => import("@/views/zhipai/index"),
    meta: {
      title: "工单指派"
    }
  },
  {
    name: "gongdan",
    path: "/gongdan",
    redirect: "/gongdan/index",
    component: () => import("@/views/layout"),
    children: [{
        name: "GongdanIndex",
        path: "index",
        component: () => import("@/views/gongdan/index"),
        meta: {
          title: "工单列表"
        }
      }, {
        name: "GongdanAdd",
        path: "add",
        component: () => import("@/views/gongdan/add"),
        meta: {
          title: "上报"
        }
      },
      {
        name: "GongdanHandle",
        path: "handle",
        component: () => import("@/views/gongdan/handle"),
        meta: {
          title: "线索处理"
        }
      },
      {
        name: "GongdanDetail",
        path: "detail",
        component: () => import("@/views/gongdan/detail"),
        meta: {
          title: "工单详情"
        }
      },
      {
        name: "GongdanRecord",
        path: "record",
        component: () => import("@/views/gongdan/record"),
        meta: {
          title: "处理记录"
        }
      }
    ]
  },
  {
    name: "gaojing",
    path: "/gaojing",
    redirect: "/gaojing/index",
    component: () => import("@/views/layout"),
    children: [{
        name: "GaojingIndex",
        path: "index",
        component: () => import("@/views/gaojing/index"),
        meta: {
          title: "告警列表"
        }
      },
      {
        name: "GaojingDetail",
        path: "detail",
        component: () => import("@/views/gaojing/detail"),
        meta: {
          title: "告警详情"
        }
      }
    ]
  },
  {
    name: "shenhe",
    path: "/shenhe",
    redirect: "/shenhe/index",
    component: () => import("@/views/layout"),
    children: [{
        name: "ShenHeIndex",
        path: "index",
        component: () => import("@/views/shenhe/index"),
        meta: {
          title: "审核列表"
        }
      },
      {
        name: "ShenHeDetail",
        path: "detail",
        component: () => import("@/views/shenhe/detail"),
        meta: {
          title: "审核详情"
        }
      }
    ]
  },
  {
    name: "device",
    path: "/device",
    redirect: "/device/index",
    component: () => import("@/views/layout"),
    children: [{
        name: "DeviceIndex",
        path: "index",
        component: () => import("@/views/device/index"),
        meta: {
          title: "设备列表"
        }
      },
      {
        name: "DeviceDetail",
        path: "detail",
        component: () => import("@/views/device/detail"),
        meta: {
          title: "设备详情"
        }
      },
      {
        name: "Device",
        path: "monitor",
        component: () => import("@/views/device/monitor"),
        meta: {
          title: "实时工况"
        }
      },
    ]
  },
  {
    name: "404",
    path: "/404",
    component: () => import("@/views/error/404"),
    meta: {
      title: "404"
    }
  },
  {
    name: "403",
    path: "/403",
    component: () => import("@/views/error/403"),
    meta: {
      title: "403"
    }
  },
  {
    name: "401",
    path: "/401",
    component: () => import("@/views/error/401"),
    meta: {
      title: "401"
    }
  },
  {
    path: "*",
    redirect: "/"
  } //默认跳转
];

// 将路由表装载到router
export default new Router({
  // require service support
  // mode: "history",
  scrollBehavior: () => ({
    y: 0
  }),
  routes: routes
});