import axios from 'axios'
import Qs from 'qs'
import store from '../store'
import router from '../router'
import {
  Toast
} from 'vant'

import {
  getStorage
} from '@/utils/auth'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api的base_url
  responseType: 'json',
  timeout: 10000, // 请求超时时间 单位：毫秒
  headers: {
    'Content-Type': 'charset=utf-8',
  }
})

// request 拦截器
service.interceptors.request.use(config => {
  // 根据api统一规则拼接url
  config.url = config.baseURL + config.path
  // 无需token的接口白名单
  const notStorage = ['loginService/appLogin']
  if (notStorage.indexOf(config.path) < 0) {
    // 设置token参数
    config.headers['X-Authorization-access-token'] = getStorage('token')
    config.headers['vaildCode'] = '{{v}}'
  }
  if (config.method === 'get') {
    const query = Qs.stringify(config.data)
    if (query) {
      config.url = config.url + '?' + query
    }
  }
  // 处理请求头,这个是个坑了 请求的头是 application/x-www-form-urlencoded 用 qs.stringify 去构造数据
  if (config.method === 'post') {
    console.log(config.dataType);
    if (config.dataType === 'json') { // json 格式 后台对应@RequestBody
      config.headers['Content-Type'] = 'application/json'
    } else if (config.dataType === 'file') { // 附件处理
      config.headers['Content-Type'] = 'multipart/form-data'
    } else { // 后台对应@RequestParam
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
      config.data = Qs.stringify(config.data)
    }
    //console.log(config)
    config.headers.post['Content-Type'] = config.headers['Content-Type']
  }
  //console.log(config)
  return config
}, error => {
  // 请求失败 
  Toast({
    message: '请求失败',
    duration: 5 * 1000
  })
  Promise.reject(error)
})

// respone 拦截器
service.interceptors.response.use(
  response => {
    // const res = JSON.parse(Base64.decode(response.data.body))
    if (response.config.responseType === 'arraybuffer') { // buffer 数据
      return response
    }
    const data = response.data
    // state为非200是抛错
    const state = data.state
    if (state !== 200) {
      // 6020201:非法的token;6020202:其他客户端登录了;6020203:Token 过期了;
      if (state === 6020201 || state === 6020102 || state === 6020203) {
        // 登录失败
        Toast({
          message: '请登录',
          duration: 5 * 1000
        })
        // 重定向到登录页
        store.dispatch('Logout').then(() => {
          // 跳转到登录页
          router.replace({
            path: '/login'
          })
        })
        return Promise.reject('请登录')
      } else { // 错误处理
        // 操作失败
        Toast({
          message: data.cause?data.cause:data.message,
          duration: 5 * 1000
        })
        return Promise.reject(data.message)
      }
    } else {
      return data
    }
  },
  error => {
    const originalRequest = error.config
    let _errText = '错误信息：'
    if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1 && !originalRequest._retry) {
      _errText += '请求超时'
    } else {
      _errText += '请求失败'
    }
    Toast({
      message: _errText,
      duration: 5 * 1000
    })
    return Promise.reject(error)
  })

export default service