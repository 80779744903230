import router from './router'
import store from './store'
import {
  getStorage,
  setStorage
} from '@/utils/auth' // getStorage from Storage

const whiteList = ['/login'] // 路由白名单
router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  const token = getStorage('token')
  if (token) { // 判断是否有token
    // 如果有token
    next()
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) >= 0) { // 在免登录白名单，直接进入     
      next()
    } else {
      setStorage('redirectUrl', to.path)
      setStorage('redirectUrlQuery', JSON.stringify(to.query))
      // 否则全部重定向到登录页
      store.dispatch('Logout').then(() => {
        // 跳转到登录页
        next({
          path: '/login'
        })
      })
    }
  }
})

router.afterEach(() => {

})