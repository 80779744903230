<template>
  <div id="app">
    <keep-alive>
      <!-- 如果当前打开页面的路由中 keepAlive: true （开启了缓存时） -->
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <!-- 如果当前打开页面的路由中 没有 或者为 keepAlive: false （关闭缓存时[默认就是false]） -->
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
export default {
  name: "APP",
  components: {}
};
</script>
<style>
[v-cloak] {
  display: none;
}
body {
  font-size: 16px;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
}
</style>
