import request from '@/utils/request'

// 登录
export function login(data) {
  return request({
    method: 'post',
    path: 'loginService/appLogin',
    data,
  })
}
//修改密码
export function changepwd(data) {
  return request({
    method: 'post',
    path: 'webapi/waterOrder/orderpwd',
    dataType: 'json',
    data,

  })
}


// 退出
export function logout(data) {
  return request({
    method: 'post',
    path: 'loginService/logout',
    data
  })
}