import {
  login
} from '@/api/auth'
import {
  getStorage,
  setStorage,
  removeStorage
} from '@/utils/auth'

const user = {
  state: {
    name: getStorage('name'), // 用户姓名
    account: getStorage('account'), // 用户手机号   
    token: getStorage('token'), // 登录token    
    uid: getStorage('uid'), // 登录token  
    super: getStorage('super'), // 登录token  
    isrember:getStorage('isrember')//是否记住密码
  },

  mutations: {
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_ACCOUNT: (state, account) => {
      state.account = account
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_UID: (state, uid) => {
      state.uid = uid
    },
    SET_Super: (state, issuper) => {
      state.issuper = issuper
    },
    SET_Super: (state, isrember) => {
      state.isrember = isrember
    },

  },

  actions: {
    // 密码登录
    login({
      commit,
    }, userInfo) {
      setStorage('loginAccount', userInfo.account)
      setStorage('loginPassword', userInfo.pwd)
      setStorage('isrember', userInfo.isrember)
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          if (response.success) {
            const data = response.data
            console.log(999);
            console.log(data)
            // 存储TOKEN
            commit('SET_TOKEN', data.token)
            commit('SET_ACCOUNT', data.account)
            commit('SET_NAME', data.fullName)
            commit('SET_UID', data.userId)
            commit('SET_Super', data.isSuper)
            // 将token保存到cookies
            setStorage('token', data.token)
            setStorage('account', data.account)
            setStorage('name', data.fullName)
            setStorage('uid', data.userId)
            setStorage('super', data.isSuper)
          }
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 登出
    Logout({
      commit
    }) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', '')
        commit('SET_NAME', '')
        commit('SET_ACCOUNT', '')
        commit('SET_UID', '')
        commit('SET_Super', '')
        removeStorage('token')
        removeStorage('name')
        removeStorage('account')
        removeStorage('uid')
        removeStorage('isSuper')
        resolve()
      })
    }
  }
}

export default user