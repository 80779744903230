import md5 from 'js-md5'
export function getStorage(key) {
  key = 'haoqee_' + key
  return localStorage.getItem(key)
}

export function setStorage(key, value) {
  key = 'haoqee_' + key
  return localStorage.setItem(key, value)
}

export function removeStorage(key) {
  key = 'haoqee_' + key
  return localStorage.removeItem(key)
}

// 排序的函数 加密
export function objKeySort(obj) {
  const newkey = Object.keys(obj).sort()
  // 先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
  let str = ''
  const newObj = {} // 创建一个新的对象，用于存放排好序的键值对
  for (var i = 0; i < newkey.length; i++) { // 遍历newkey数组
    // sign 以及 空值不参与加密
    if (newkey[i] !== 'sign' && obj[newkey[i]]) {
      newObj[newkey[i]] = obj[newkey[i]] // 向新创建的对象中按照排好的顺序依次增加键值对
      str += '&' + newkey[i] + '=' + obj[newkey[i]]
    }
  }
  const timestamp = new Date().getTime()
  let sign = str.substring(1, str.length) + '&key=qwertyuiopASDFGHJKLzxcvbnm'
  sign = md5(sign)
  return [newObj, timestamp, sign.toUpperCase()]
}

// 时间戳转日期
export function timestampToTime(timestamp) {
  const date = new Date(timestamp * 1000) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
  const Y = date.getFullYear() + '-'
  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  const D = date.getDate() + ' '
  const h = date.getHours() + ':'
  const m = date.getMinutes() + ':'
  const s = date.getSeconds()
  return Y + M + D + h + m + s
}