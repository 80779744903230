import "amfe-flexible";
import Vue from "vue";

import App from "./App";
import router from "./router";
import store from "./store";
import "./permission"; // permission control
// 过滤器
import * as filters from "./filters"; // global filters
//全局方法
import * as utils from './utils' // global fundtions
Vue.prototype.utils = utils
//加载vconsole
// import vConsole from './utils/vconsole.js'
// Vue.prototype.vConsole = vConsole;

//懒加载
import {
  Lazyload
} from "vant";
Vue.use(Lazyload);
// 图片预览
import {
  ImagePreview
} from "vant";
Vue.use(ImagePreview);
// register global utility filters.
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});
import "@/styles/index.scss"; // global css
Vue.config.productionTip = false;

new Vue({
  el: "#app",
  router,
  store,
  template: "<App/>",
  components: {
    App
  }
});